<script>
export default {
  name: 'RadioItem',
  components: {
    Icon: () => import('@/components/general/Icon')
  },
  props: {
    switchType: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: null,
    index: {
      type: Number,
      default: null
    },
    name: {
      type: Number,
      default: null
    },
    custom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFocused: false
    }
  },
  methods: {
    updateValue () {
      this.$emit('change', this.value)
    },
    updateFocus () {
      this.isFocused = true
    },
    updateBlur () {
      this.isFocused = false
    }
  }
}
</script>

<template>
  <div
    class="form-radio-item"
    :class="{ 'is-disabled': disabled, 'is-checked': checked, 'has-focus': isFocused, 'is-custom': custom }"
  >
    <input
      :id="'radio-' + _uid + '-' + index"
      class="form-radio"
      type="radio"
      :name="'radio-' + name"
      :disabled="disabled"
      :readonly="readonly"
      :checked="checked"
      :value="value"
      @focus="updateFocus()"
      @blur="updateBlur()"
      @change="updateValue()"
    >
    <icon
      v-show="checked"
      v-if="!custom"
      name="radio_button_checked"
    />
    <icon
      v-show="!checked"
      v-if="!custom"
      name="radio_button_unchecked"
    />
    <label
      class="form-radio-label"
      :for="'radio-' + _uid + '-' + index"
      :data-label="label"
      v-html="label"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>
